import { useState } from "react";
import GeneralFileUpload from "../../components/GeneralFileUpload";

const TestInputChrome = () => {
  const [files, setFiles] = useState([]);

  const handleSaveFiles = (newFiles) => {
    setFiles([...files, ...newFiles]);
  };

  const handleRemoveFile = (file) => {
    const newFiles = files.filter((fileObj) => fileObj.name !== file.name);

    setFiles(newFiles);
  };

  return (
    <GeneralFileUpload
      multiple
      handleRemoveFile={handleRemoveFile}
      handleSaveFiles={handleSaveFiles}
      files={files}
    />
  );
};
export default TestInputChrome;
