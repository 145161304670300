import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { printGeneralV2 } from "../../../utils/printer-functions/printGeneralV2";

import Loading from "../../../components/Loading";
import axios from "../../../axios/axios.config";
import FlexWrapper from "../../../components/FlexWrapper";

const PrintBinLabels = () => {
  const { currentPrinter } = useSelector((state) => state.printers);
  const { currentNetsuiteLocationIds } = useSelector((state) => state.user);

  const [startingBin, setStartingBin] = useState("");
  const [endingBin, setEndingBin] = useState("");
  const [loading, setLoading] = useState("");
  const [error, setError] = useState(
    !currentPrinter?._id ? "No printer selected. Select one in settings." : ""
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (error) {
      setError("");
    }
    setLoading("Printing Bins...");
    try {
      //get bin data
      const response = await axios.post("netsuite/labels/bin-range", {
        startingBin,
        endingBin,
        nsLocations: currentNetsuiteLocationIds?.join(","),
      });

      if (!response?.data?.length) {
        throw new Error(
          `No data found for the following bins: ${startingBin} - ${endingBin}`
        );
      }

      //create zpl
      const zplCode = [];
      for (const bin of response.data) {
        zplCode.push(`^XA
^MMT^PW1200^LL0600
^LS0^PON^LH0,0
^FT100,375^BY8,2,300^BCN,,N,N^FD${bin?.binnumber}^FS
^FT0,775^FB1200,2,0,C,0^A0N,200^FD${bin?.binnumber}^FS
^PQ1,0,1,Y
^XZ`);
      }

      await printGeneralV2({
        currentPrinter,
        title: "Print Bin Labels",
        template: zplCode.join(""),
      });

      handleReset();
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const handleReset = () => {
    setStartingBin("");
    setEndingBin("");
    setError("");
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Print Bin Labels
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <FlexWrapper
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <TextField
            fullWidth
            disabled={!currentPrinter?._id}
            autoFocus
            required
            margin="dense"
            label="Enter Starting Bin"
            value={startingBin}
            onChange={(event) =>
              setStartingBin(event.target.value.toUpperCase())
            }
            variant="outlined"
          />

          <TextField
            fullWidth
            disabled={!currentPrinter?._id}
            required
            margin="dense"
            label="Enter Ending Bin"
            value={endingBin}
            onChange={(event) => setEndingBin(event.target.value.toUpperCase())}
            variant="outlined"
          />
          {Boolean(startingBin) &&
            Boolean(endingBin) &&
            Boolean(currentPrinter?._id) && (
              <Button
                type="submit"
                variant="contained"
                disabled={Boolean(loading)}
              >
                Submit
              </Button>
            )}
          {error ? (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          ) : null}
        </FlexWrapper>
      </Box>
    </Container>
  );
};
export default PrintBinLabels;
