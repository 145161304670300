import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GeneralModalV3 from "../../general/GeneralModalV3";
import { Email, EmailOutlined } from "@mui/icons-material";
import { useState } from "react";
import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config";
import { useSelector } from "react-redux";
import Loading from "../../Loading";

const InventoryMoveReportBinIssue = ({ emptyBin, handleFindEmptyBin }) => {
  const [radioInput, setRadioInput] = useState("");
  const [otherInput, setOtherInput] = useState("");
  const [loading, setLoading] = useState("");

  const { username } = useSelector((state) => state.user);

  const handleSubmit = async (event, handleClose) => {
    event.preventDefault();
    setLoading("Reporting Issue...");
    try {
      const binIssue = radioInput === "Other" ? otherInput : radioInput;

      await axios.patch(
        "multi-actions/inventory-move/find-empty-bin/alert-inventory-control",
        {
          errorMessage: binIssue,
          emptyBinId: emptyBin._id,
          newStatus: "error",
          bininternalid: emptyBin.bininternalid,
          binnumber: emptyBin.binnumber,
          iteminternalid: 72817,
          username,
          toEmail:
            process.env.REACT_APP_TEST_EMAIL || "cyclecounts@trakmotive.com",
        }
      );

      setLoading("");
      handleClose();
      await handleFindEmptyBin();
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleRadioChanges = (event) => {
    setRadioInput(event.target.value);

    if (event.target.value !== "Other" && otherInput) {
      setOtherInput("");
    }
  };

  return (
    <GeneralModalV3
      disableEscapeKeyDown
      disableClick={Boolean(loading)}
      openComponent={
        <Button
          size="small"
          variant="text"
          color="error"
          endIcon={<Email />}
          sx={{ textDecoration: "underline" }}
        >
          Report issue with bin
        </Button>
      }
      onClose={() => {
        setRadioInput("");
        setOtherInput("");
      }}
    >
      {(handleClose) =>
        loading ? (
          <Loading message={loading} />
        ) : (
          <Box
            component="form"
            onSubmit={(event) => handleSubmit(event, handleClose)}
          >
            <Typography py={1} variant="h5" textAlign="center">
              Alert Inventory Control
            </Typography>
            <Divider />
            <Box sx={{ p: 1 }}>
              <FlexWrapper>
                <Typography>
                  <strong>Bin:</strong> {emptyBin?.binnumber}
                </Typography>
              </FlexWrapper>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={radioInput}
                  onChange={handleRadioChanges}
                >
                  <FormControlLabel
                    value="Bin Does Not Exist"
                    control={<Radio />}
                    label="Bin Does Not Exist"
                  />
                  <FormControlLabel
                    value="Bin Is Not Empty"
                    control={<Radio />}
                    label="Bin Is Not Empty"
                  />
                  <FormControlLabel
                    value="Wrong Bin Brand or Size Type"
                    control={<Radio />}
                    label="Wrong Bin Brand or Size Type"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>

              {radioInput === "Other" && (
                <TextField
                  required
                  autoFocus
                  size="small"
                  fullWidth
                  value={otherInput}
                  onChange={(event) => setOtherInput(event.target.value)}
                  label="Enter Issue"
                  multiline
                  maxRows={2}
                  sx={{ my: 1 }}
                />
              )}

              <Button
                variant="contained"
                fullWidth
                type="submit"
                disabled={
                  !radioInput || (radioInput === "Other" && !otherInput)
                }
                endIcon={<EmailOutlined fontSize="small" />}
              >
                Submit
              </Button>
            </Box>
          </Box>
        )
      }
    </GeneralModalV3>
  );
};
export default InventoryMoveReportBinIssue;
