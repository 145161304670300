import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axios/axios.config";

const initialState = {
  procurementLoading: false,
  procurementError: "",
  understockAnalysis: [],
  understockAnalysisLastUpdateDate: "",
  filteredUnderstockAnalysis: [],
  understockAnalysisError: "",
  understockAnalysisLoading: false,
  replenishmentAnalysis: [],
  replenishmentAnalysisLastUpdateDate: "",
  filteredReplenishmentAnalysis: [],
  replenishmentLoading: false,
  replenishmentError: "",
  overstockAnalysis: [],
  filteredOverstockAnalysis: [],
  overstockAnalysisLastUpdatedDate: "",
  overstockAnalysisLoading: false,
  overstockAnalysisError: "",
  allItems: [],
  filteredAllItems: [],
  allItemsLastUpdateDate: "",
  allItemsLoading: false,
  allItemsError: "",
};

export const createUnderstockAnalysisData = createAsyncThunk(
  "procurement/createUnderstockAnalysisData",
  async (_, thunkAPI) => {
    try {
      //starting point: understock data
      const understockResponse = await axios.post("procurement", {
        filter: {
          Understock: { $lt: 0 },
          Brand: { $ne: "NISSAN" },
        },
        sort: { "Days of Supply": 1 },
      });

      //get overstock data
      const overstockResponse = await axios.post("procurement", {
        filter: {
          Overstock: { $gt: 0 },
          customer_releasedate: { $ne: null },
          Brand: { $ne: "NISSAN" },
        },
        sort: { Overstock: -1 },
      });

      return {
        data: mergeData(understockResponse.data, overstockResponse.data),
        lastUpdateDate: understockResponse.data[0].createdAt,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createReplenishmentAnalysisData = createAsyncThunk(
  "procurement/createReplenishmentAnalysisData",
  async (_, thunkAPI) => {
    try {
      //starting array
      const replenishmentResponse = await axios.post("replenishment", {
        filter: { "Need Quantity": { $lt: 0 } },
        sort: { "Days Of Supply": 1 },
      });
      //comparison array
      const procurementResponse = await axios.post("procurement", {
        filter: {
          Overstock: { $gt: 0 },
        },
        sort: { Overstock: -1 },
      });

      return {
        data: mergeReplenishmentAnalysis(
          replenishmentResponse.data,
          procurementResponse.data
        ),
        lastUpdateDate: replenishmentResponse.data[0].createdAt,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createOverstockAnalysis = createAsyncThunk(
  "procurement/createOverstockAnalysis",
  async (_, thunkAPI) => {
    try {
      const overstockResponse = await axios.post("procurement", {
        filter: {
          Overstock: { $gt: 0 },
        },
        sort: { Overstock: -1 },
      });

      const understockResponse = await axios.post("procurement", {
        filter: {
          Understock: { $lt: 0 },
        },
        sort: { Understock: 1 },
      });

      return {
        data: mergeOverstockAnalysis(
          overstockResponse.data,
          understockResponse.data
        ),
        lastUpdated: overstockResponse.data[0].createdAt,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAllItems = createAsyncThunk(
  "procurement/getAllItems",
  async (_, thunkAPI) => {
    try {
      const procurementResponse = await axios.post("procurement", {
        filter: {},
        sort: {},
      });

      return {
        data: procurementResponse.data,
        allItemsLastUpdateDate: procurementResponse.data[0].createdAt,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

/* HELPER FUNCTIONS */
//merge data from starting dataset to the comparison dataset (understock analysis)
const mergeData = (startingArr, comparisonArr) => {
  const mergedArray = [];

  //find matches for each item in starting array
  for (let i = 0; i < startingArr.length; i++) {
    const startingElement = startingArr[i];
    const matches = [];
    //set up variables for understock comparisons
    let toOnOrderQty = parseInt(
      startingElement.to_onorder ? startingElement.to_onorder : 0
    );
    let understock = parseInt(startingElement.Understock);
    let remainingUnderstock = understock + toOnOrderQty; // understock is negative and to onorder is positive

    for (let j = 0; j < comparisonArr.length; j++) {
      const comparisonElement = comparisonArr[j];
      //Add match if found
      if (
        startingElement["Base Number"] === comparisonElement["Base Number"] &&
        comparisonElement.Overstock > 0 &&
        !comparisonElement["Item Number"].startsWith("C9") &&
        !comparisonElement.newpart
      ) {
        //Add comparisonElement with same location and with most overstock as startingElement to beginning

        if (
          matches.length === 0 ||
          comparisonElement["DC Location"] !== startingElement["DC Location"]
        ) {
          matches.push({ ...comparisonElement, index: j });
        } else {
          //insert comparisonElement with matching Locations in beginning with highest Overstock first
          let i = 0;
          while (i < matches.length) {
            if (i === matches.length - 1) {
              if (
                matches[matches.length - 1]["DC Location"] !==
                startingElement["DC Location"]
              ) {
                //if last element is not of same DC Location then add before
                matches.splice(i, 0, { ...comparisonElement, index: j });
                break;
              }
              //if only one element left then add after
              matches.push({ ...comparisonElement, index: j });
              break;
            }
            if (
              comparisonElement["DC Location"] !== matches[i]["DC Location"]
            ) {
              //no matching location then insert before next element
              matches.splice(i, 0, { ...comparisonElement, index: j });
              break;
            }
            i++;
          }
        }
      }
    }
    //fill out transfers with matches
    if (matches.length === 0) {
      mergedArray.push({
        line: mergedArray.length + 1,
        forecasttotal: Math.ceil(startingElement.forecasttotal),
        brand: startingElement.Brand,
        boxsize: startingElement.boxsize,
        customerReleaseDate: startingElement.customer_releasedate,
        itemClass: startingElement.ItemClass,
        releaseStatus: startingElement.releasestatus,
        baseNumber: startingElement["Base Number"],
        item: startingElement["Item Number"],
        itemInterchange: "",
        newpart: startingElement.newpart,
        available: startingElement.Available,
        onOrder: startingElement.ordered,
        toPlanningGroup: startingElement.planninggroup,
        qty:
          Math.abs(startingElement.Understock) - toOnOrderQty < 0
            ? 0
            : Math.abs(startingElement.Understock) - toOnOrderQty,
        overstock: startingElement.Overstock,
        understock: startingElement.Understock,
        monthsOfSupply: Number(startingElement["Months of Supply"].toFixed(2)),
        fromLocation: "",
        fromPlanningGroup: "",
        toLocation: startingElement["Location"],
        action: "None Available To Transfer",
        lastSODate: startingElement.last_so_date
          ? new Date(startingElement.last_so_date).toLocaleDateString()
          : "",
        lastTOReceiptDate: startingElement.last_to_receipt_date
          ? new Date(startingElement.last_to_receipt_date).toLocaleDateString()
          : "",
        lastPOReceiptDate: startingElement.last_po_receipt_date
          ? new Date(startingElement.last_po_receipt_date).toLocaleDateString()
          : "",
        TOFirstExpectedReceiptDate: startingElement.to_firstexpectedreceiptdate
          ? new Date(
              startingElement.to_firstexpectedreceiptdate
            ).toLocaleDateString()
          : "",
        TOLastExpectedReceiptDate: startingElement.to_lastexpectedreceiptdate
          ? new Date(
              startingElement.to_lastexpectedreceiptdate
            ).toLocaleDateString()
          : "",
        TOExpectedReceiptDetails: startingElement.to_expectedreceipdetails
          ? startingElement.to_expectedreceipdetails
          : "",
        TO_OnOrder: startingElement.to_onorder ? startingElement.to_onorder : 0,
        POFirstExpectedReceiptDate: startingElement.po_firstexpectedreceiptdate
          ? new Date(
              startingElement.po_firstexpectedreceiptdate
            ).toLocaleDateString()
          : "",
        POLastExpectedReceiptDate: startingElement.po_lastexpectedreceiptdate
          ? new Date(
              startingElement.po_lastexpectedreceiptdate
            ).toLocaleDateString()
          : "",
        POExpectedReceiptDetails: startingElement.po_expectedreceipdetails
          ? startingElement.po_expectedreceipdetails
          : "",
        PO_OnOrder: startingElement.po_onorder
          ? startingElement.po_onorder
          : "",
      });
      continue;
    }

    //loop through matches and add transfer objects
    for (let match of matches) {
      if (remainingUnderstock > 0) {
        // means TO on order qty is greater than understock qty
        mergedArray.push({
          line: mergedArray.length + 1,
          forecasttotal: Math.ceil(startingElement.forecasttotal),
          brand: startingElement.Brand,
          boxsize: startingElement.boxsize,
          customerReleaseDate: startingElement.customer_releasedate,
          itemClass: startingElement.ItemClass,
          releaseStatus: startingElement.releasestatus,
          baseNumber: startingElement["Base Number"],
          item: startingElement["Item Number"],
          itemInterchange: "",
          newpart: startingElement.newpart,
          available: startingElement.Available,
          onOrder: startingElement.ordered,
          toPlanningGroup: startingElement.planninggroup,
          qty: 0,
          overstock: startingElement.Overstock,
          understock: startingElement.Understock,
          monthsOfSupply: Number(
            startingElement["Months of Supply"].toFixed(2)
          ),
          fromLocation: "",
          fromPlanningGroup: "",
          toLocation: startingElement["Location"],
          action: "None Available To Transfer",
          lastSODate: startingElement.last_so_date
            ? new Date(startingElement.last_so_date).toLocaleDateString()
            : "",
          lastTOReceiptDate: startingElement.last_to_receipt_date
            ? new Date(
                startingElement.last_to_receipt_date
              ).toLocaleDateString()
            : "",
          lastPOReceiptDate: startingElement.last_po_receipt_date
            ? new Date(
                startingElement.last_po_receipt_date
              ).toLocaleDateString()
            : "",
          TOFirstExpectedReceiptDate:
            startingElement.to_firstexpectedreceiptdate
              ? new Date(
                  startingElement.to_firstexpectedreceiptdate
                ).toLocaleDateString()
              : "",
          TOLastExpectedReceiptDate: startingElement.to_lastexpectedreceiptdate
            ? new Date(
                startingElement.to_lastexpectedreceiptdate
              ).toLocaleDateString()
            : "",
          TOExpectedReceiptDetails: startingElement.to_expectedreceipdetails
            ? startingElement.to_expectedreceipdetails
            : "",
          TO_OnOrder: toOnOrderQty,
          POFirstExpectedReceiptDate:
            startingElement.po_firstexpectedreceiptdate
              ? new Date(
                  startingElement.po_firstexpectedreceiptdate
                ).toLocaleDateString()
              : "",
          POLastExpectedReceiptDate: startingElement.po_lastexpectedreceiptdate
            ? new Date(
                startingElement.po_lastexpectedreceiptdate
              ).toLocaleDateString()
            : "",
          POExpectedReceiptDetails: startingElement.po_expectedreceipdetails
            ? startingElement.po_expectedreceipdetails
            : "",
          PO_OnOrder: startingElement.po_onorder
            ? startingElement.po_onorder
            : "",
        });

        //set understock element to 0 and dont edit overstocks
        startingElement.Understock = 0;
        remainingUnderstock = 0;
        break;
      }

      if (match.Overstock >= Math.abs(remainingUnderstock)) {
        mergedArray.push({
          line: mergedArray.length + 1,
          forecasttotal: Math.ceil(startingElement.forecasttotal),
          itemClass: startingElement.ItemClass,
          releaseStatus: startingElement.releasestatus,
          baseNumber: startingElement["Base Number"],
          item: match["Item Number"],
          newpart: startingElement.newpart,
          brand: startingElement.Brand,
          boxsize: startingElement.boxsize,
          customerReleaseDate: startingElement.customer_releasedate,
          itemInterchange:
            match["Item Number"] !== startingElement["Item Number"]
              ? startingElement["Item Number"]
              : "",
          available: startingElement.Available,
          onOrder: startingElement.ordered,
          toPlanningGroup: startingElement.planninggroup,
          qty: Math.abs(remainingUnderstock),
          overstock: match.Overstock,
          understock: understock,
          monthsOfSupply: Number(
            startingElement["Months of Supply"].toFixed(2)
          ),
          fromLocation: match.Location,
          fromPlanningGroup: match.planninggroup,
          toLocation: startingElement.Location,
          action: "Transfer",
          lastSODate: startingElement.last_so_date
            ? new Date(startingElement.last_so_date).toLocaleDateString()
            : "",
          lastTOReceiptDate: startingElement.last_to_receipt_date
            ? new Date(
                startingElement.last_to_receipt_date
              ).toLocaleDateString()
            : "",
          lastPOReceiptDate: startingElement.last_po_receipt_date
            ? new Date(
                startingElement.last_po_receipt_date
              ).toLocaleDateString()
            : "",
          TOFirstExpectedReceiptDate:
            startingElement.to_firstexpectedreceiptdate
              ? new Date(
                  startingElement.to_firstexpectedreceiptdate
                ).toLocaleDateString()
              : "",
          TOLastExpectedReceiptDate: startingElement.to_lastexpectedreceiptdate
            ? new Date(
                startingElement.to_lastexpectedreceiptdate
              ).toLocaleDateString()
            : "",
          TOExpectedReceiptDetails: startingElement.to_expectedreceipdetails
            ? startingElement.to_expectedreceipdetails
            : "",
          TO_OnOrder: toOnOrderQty,
          POFirstExpectedReceiptDate:
            startingElement.po_firstexpectedreceiptdate
              ? new Date(
                  startingElement.po_firstexpectedreceiptdate
                ).toLocaleDateString()
              : "",
          POLastExpectedReceiptDate: startingElement.po_lastexpectedreceiptdate
            ? new Date(
                startingElement.po_lastexpectedreceiptdate
              ).toLocaleDateString()
            : "",
          POExpectedReceiptDetails: startingElement.po_expectedreceipdetails
            ? startingElement.po_expectedreceipdetails
            : "",
          PO_OnOrder: startingElement.po_onorder
            ? startingElement.po_onorder
            : "",
        });
        //Update match overstock in original array to avoid future incorrect quantities
        comparisonArr[match.index].Overstock += remainingUnderstock;

        //set understock element to 0
        startingElement.Understock = 0;
        remainingUnderstock = 0;
        break;
      } else {
        //overstock is less than understock - to on order
        mergedArray.push({
          line: mergedArray.length + 1,
          forecasttotal: Math.ceil(startingElement.forecasttotal),
          itemClass: startingElement.ItemClass,
          releaseStatus: startingElement.releasestatus,
          baseNumber: startingElement["Base Number"],
          item: match["Item Number"],
          newpart: startingElement.newpart,
          brand: startingElement.Brand,
          boxsize: startingElement.boxsize,
          customerReleaseDate: startingElement.customer_releasedate,
          itemInterchange:
            match["Item Number"] !== startingElement["Item Number"]
              ? startingElement["Item Number"]
              : "",
          available: startingElement.Available,
          onOrder: startingElement.ordered,
          toPlanningGroup: startingElement.planninggroup,
          qty: match.Overstock,
          overstock: match.Overstock,
          understock: understock,

          monthsOfSupply: Number(
            startingElement["Months of Supply"].toFixed(2)
          ),
          fromLocation: match.Location,
          fromPlanningGroup: match.planninggroup,
          toLocation: startingElement["Location"],
          action: "Transfer",
          lastSODate: startingElement.last_so_date
            ? new Date(startingElement.last_so_date).toLocaleDateString()
            : "",
          lastTOReceiptDate: startingElement.last_to_receipt_date
            ? new Date(
                startingElement.last_to_receipt_date
              ).toLocaleDateString()
            : "",
          lastPOReceiptDate: startingElement.last_po_receipt_date
            ? new Date(
                startingElement.last_po_receipt_date
              ).toLocaleDateString()
            : "",
          TOFirstExpectedReceiptDate:
            startingElement.to_firstexpectedreceiptdate
              ? new Date(
                  startingElement.to_firstexpectedreceiptdate
                ).toLocaleDateString()
              : "",
          TOLastExpectedReceiptDate: startingElement.to_lastexpectedreceiptdate
            ? new Date(
                startingElement.to_lastexpectedreceiptdate
              ).toLocaleDateString()
            : "",
          TOExpectedReceiptDetails: startingElement.to_expectedreceipdetails
            ? startingElement.to_expectedreceipdetails
            : "",
          TO_OnOrder: toOnOrderQty,
          POFirstExpectedReceiptDate:
            startingElement.po_firstexpectedreceiptdate
              ? new Date(
                  startingElement.po_firstexpectedreceiptdate
                ).toLocaleDateString()
              : "",
          POLastExpectedReceiptDate: startingElement.po_lastexpectedreceiptdate
            ? new Date(
                startingElement.po_lastexpectedreceiptdate
              ).toLocaleDateString()
            : "",
          POExpectedReceiptDetails: startingElement.po_expectedreceipdetails
            ? startingElement.po_expectedreceipdetails
            : "",
          PO_OnOrder: startingElement.po_onorder
            ? startingElement.po_onorder
            : "",
        });

        //update remaining quantity
        remainingUnderstock += match.Overstock;

        //update understock value
        understock = remainingUnderstock;
        //to qty is now 0
        toOnOrderQty = 0;

        //make match overstock 0
        comparisonArr[match.index].Overstock = 0;
      }
    }
    //Check for Understock Quantity leftover
    if (Math.abs(remainingUnderstock) > 0) {
      mergedArray.push({
        line: mergedArray.length + 1,
        forecasttotal: Math.ceil(startingElement.forecasttotal),
        baseNumber: startingElement["Base Number"],
        itemClass: startingElement.ItemClass,
        releaseStatus: startingElement.releasestatus,
        item: startingElement["Item Number"],
        newpart: startingElement.newpart,
        brand: startingElement.Brand,
        boxsize: startingElement.boxsize,
        customerReleaseDate: startingElement.customer_releasedate,
        itemInterchange: "",
        available: startingElement.Available,
        onOrder: startingElement.ordered,
        toPlanningGroup: startingElement.planninggroup,
        qty: Math.abs(remainingUnderstock),
        overstock: startingElement.Overstock,
        understock: remainingUnderstock,
        monthsOfSupply: Number(startingElement["Months of Supply"].toFixed(2)),
        fromLocation: "",
        fromPlanningGroup: "",
        toLocation: startingElement.Location,
        action: "None Available To Transfer",
        lastSODate: startingElement.last_so_date
          ? new Date(startingElement.last_so_date).toLocaleDateString()
          : "",
        lastTOReceiptDate: startingElement.last_to_receipt_date
          ? new Date(startingElement.last_to_receipt_date).toLocaleDateString()
          : "",
        lastPOReceiptDate: startingElement.last_po_receipt_date
          ? new Date(startingElement.last_po_receipt_date).toLocaleDateString()
          : "",
        TOFirstExpectedReceiptDate: startingElement.to_firstexpectedreceiptdate
          ? new Date(
              startingElement.to_firstexpectedreceiptdate
            ).toLocaleDateString()
          : "",
        TOLastExpectedReceiptDate: startingElement.to_lastexpectedreceiptdate
          ? new Date(
              startingElement.to_lastexpectedreceiptdate
            ).toLocaleDateString()
          : "",
        TOExpectedReceiptDetails: startingElement.to_expectedreceipdetails
          ? startingElement.to_expectedreceipdetails
          : "",
        TO_OnOrder: toOnOrderQty,
        POFirstExpectedReceiptDate: startingElement.po_firstexpectedreceiptdate
          ? new Date(
              startingElement.po_firstexpectedreceiptdate
            ).toLocaleDateString()
          : "",
        POLastExpectedReceiptDate: startingElement.po_lastexpectedreceiptdate
          ? new Date(
              startingElement.po_lastexpectedreceiptdate
            ).toLocaleDateString()
          : "",
        POExpectedReceiptDetails: startingElement.po_expectedreceipdetails
          ? startingElement.po_expectedreceipdetails
          : "",
        PO_OnOrder: startingElement.po_onorder
          ? startingElement.po_onorder
          : "",
      });
    }
  }
  return mergedArray;
};

const mergeReplenishmentAnalysis = (startingArr, comparisonArr) => {
  const transferData = [];

  for (const replenishmentObj of startingArr) {
    //Get array of items that match replenishment base number
    const matches = [];
    let itemClass = replenishmentObj["itemclass"];
    for (let i = 0; i < comparisonArr.length; i++) {
      const historyAndForecastObj = comparisonArr[i];

      if (
        replenishmentObj["Base Number"] ===
          historyAndForecastObj["Base Number"] &&
        historyAndForecastObj.Overstock !== 0 &&
        !historyAndForecastObj["Item Number"].startsWith("C9") &&
        !historyAndForecastObj.newpart
      ) {
        matches.push({ ...historyAndForecastObj, index: i });
      }
    }
    //add transfer data with the matches array
    let needQty = Math.abs(replenishmentObj["Need Quantity"]);
    const toLocation = replenishmentObj["location"];
    const brand = replenishmentObj["Brand"];
    const daysOfSupply =
      replenishmentObj["Days Of Supply"] !== undefined
        ? replenishmentObj["Days Of Supply"]
        : null;

    //Need to buy if no matches or Nissan (Cant transfer Nissan parts)
    if (matches.length === 0 || replenishmentObj.itemnumber.startsWith("C9")) {
      transferData.push({
        line: transferData.length + 1,
        "Item Class": itemClass,
        "Item Number": replenishmentObj.itemnumber,
        Qty: needQty,
        "From Location": "",
        "To Location": toLocation,
        action: "Buy",
        "Item Interchange": "",
        brand,
        daysOfSupply: daysOfSupply,
      });
      continue;
    }

    //Calculate how much to transfer from overstock matches
    for (let i = 0; i < matches.length; i++) {
      const match = matches[i];
      //if overtock can cover the needed item
      if (match.Overstock >= needQty) {
        transferData.push({
          line: transferData.length + 1,
          "Item Class": itemClass,
          "Item Number": match["Item Number"],
          "Item Interchange":
            match["Item Number"] !== replenishmentObj.itemnumber
              ? replenishmentObj.itemnumber
              : "",
          Qty: needQty,
          "From Location": match.Location,
          "To Location": toLocation,
          action: "Transfer",
          brand,
          daysOfSupply: daysOfSupply,
          customerReleaseDate: match.customer_releasedate,
          newpart: match.newpart,
        });
        //subtract overstock in original history and forecast array
        comparisonArr[match.index].Overstock -= needQty;
        //set need quantity to 0
        needQty = 0;
        //exit loop
        break;
      }
      //if overstock is less than need quantity
      transferData.push({
        line: transferData.length + 1,
        "Item Class": itemClass,
        "Item Number": match["Item Number"],
        "Item Interchange":
          match["Item Number"] !== replenishmentObj.itemnumber
            ? replenishmentObj.itemnumber
            : "",
        Qty: match.Overstock,
        "From Location": match.Location,
        "To Location": toLocation,
        action: "Transfer",
        brand,
        daysOfSupply,
        customerReleaseDate: match.customer_releasedate,
        newpart: match.newpart,
      });
      //subtract overstock from need quantity
      needQty -= match.Overstock;
      //set original Overstock to 0
      comparisonArr[match.index].Overstock = 0;

      //if there is still need quantity after loop
      if (needQty > 0 && i === matches?.length - 1) {
        transferData.push({
          line: transferData.length + 1,
          "Item Class": itemClass,
          "Item Number": replenishmentObj.itemnumber,
          "Item Interchange": "",
          Qty: needQty,
          "From Location": "",
          "To Location": toLocation,
          action: "Buy",
          brand,
          daysOfSupply,
          customerReleaseDate: match.customer_releasedate,
          newpart: match.newpart,
        });
      }
    }
  }
  return transferData;
};

const mergeOverstockAnalysis = (startingArr, comparisonArr) => {
  const results = [];
  for (const overstockObj of startingArr) {
    const {
      _id,
      "Item Number": itemNumber,
      Overstock: overstock,
      "Base Number": baseNumber,
      planninggroup,
      "DC Location": dcLocation,
      last_so_date,
      Brand,
      ItemClass,
      Location,
      "Days of Supply": daysofsupply,
      forecasttotal,
      newpart,
      customer_releasedate,
      releasestatus,
    } = overstockObj;

    const matches = comparisonArr.filter(
      (item) => item["Base Number"] === baseNumber
    );

    let needTrakMotiveCA = 0;
    let needTrakMotiveNC = 0;
    let needAutozone = 0;
    let needOreilly = 0;
    let needNapa = 0;
    let needBBNC = 0;

    for (let match of matches) {
      if (match.Brand === "TrakMotive" && match["DC Location"] === "CA") {
        needTrakMotiveCA += Math.abs(match.Understock);
        continue;
      }
      if (match.Brand === "TrakMotive" && match["DC Location"] === "NC") {
        needTrakMotiveNC += Math.abs(match.Understock);
        continue;
      }
      if (match.Brand === "AutoZone") {
        needAutozone += Math.abs(match.Understock);
        continue;
      }
      if (match.Brand === "O'Reilly") {
        needOreilly += Math.abs(match.Understock);
        continue;
      }
      if (match.Brand === "NAPA") {
        needNapa += Math.abs(match.Understock);
        continue;
      }
      if (match.Location === "BB NC") {
        needBBNC += Math.abs(match.Understock);
        continue;
      }
    }

    const totalUnderstock =
      needTrakMotiveCA +
      needTrakMotiveNC +
      needAutozone +
      needOreilly +
      needNapa +
      needBBNC;
    const netOverstock = overstockObj.Overstock - totalUnderstock;

    results.push({
      _id,
      itemNumber,
      baseNumber,
      planninggroup,
      dcLocation,
      overstock,
      last_so_date: last_so_date
        ? new Date(last_so_date).toLocaleDateString()
        : "",
      needTrakMotiveCA,
      needTrakMotiveNC,
      needAutozone,
      needOreilly,
      needNapa,
      needBBNC,
      totalUnderstock,
      netOverstock,
      Brand,
      ItemClass,
      Location,
      newpart,
      customer_releasedate,
      releasestatus,
      daysofsupply: Math.floor(daysofsupply),
      forecasttotal:
        forecasttotal === 0 ? forecasttotal : forecasttotal.toFixed(2),
    });
  }
  return results;
};

const isReleaseDateOlderThanTwoYears = (timeString) => {
  //we return true if null because we want to exclude null values
  if (!timeString) return false;
  // Parse the time string into a Date object
  const givenDate = new Date(timeString);
  // Calculate the current date two years ago
  const currentDateTwoYearsAgo = new Date();
  currentDateTwoYearsAgo.setFullYear(currentDateTwoYearsAgo.getFullYear() - 2);
  // Compare the given date with the current date two years ago
  return givenDate > currentDateTwoYearsAgo;
};

const procurementSlice = createSlice({
  name: "procurement",
  initialState,
  reducers: {
    clearStates: (state) => {
      state.procurementError = "";
      state.procurementLoading = false;
    },
    setFilteredUnderstockAnalysis: (state, action) => {
      state.filteredUnderstockAnalysis = action.payload;
    },
    resetUnderstockAnalysisFilters: (state) => {
      state.filteredUnderstockAnalysis = state.understockAnalysis;
    },
    setFiltered: (state, { payload }) => {
      state[payload.filteredArr] = payload.newArr;
    },
    resetFiltered: (state, { payload }) => {
      state[payload.filteredArr] = state[payload.originalArr];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createUnderstockAnalysisData.pending, (state) => {
        state.understockAnalysisLoading = true;
        state.understockAnalysisError = "";
      })
      .addCase(createUnderstockAnalysisData.fulfilled, (state, action) => {
        state.understockAnalysis = action.payload.data;
        state.filteredUnderstockAnalysis = action.payload.data;
        state.understockAnalysisLastUpdateDate = action.payload.lastUpdateDate;
        state.understockAnalysisLoading = false;
      })
      .addCase(createUnderstockAnalysisData.rejected, (state, action) => {
        state.understockAnalysisLoading = false;
        state.understockAnalysisError = action.payload;
      })
      .addCase(createReplenishmentAnalysisData.pending, (state, action) => {
        state.replenishmentLoading = true;
        state.replenishmentError = "";
      })
      .addCase(
        createReplenishmentAnalysisData.fulfilled,
        (state, { payload }) => {
          state.replenishmentLoading = false;
          state.replenishmentAnalysis = payload.data;
          state.filteredReplenishmentAnalysis = payload.data;
          state.replenishmentAnalysisLastUpdateDate = payload.lastUpdateDate;
        }
      )
      .addCase(
        createReplenishmentAnalysisData.rejected,
        (state, { payload }) => {
          state.replenishmentLoading = false;
          state.replenishmentError = payload;
          state.replenishmentAnalysis = [];
          state.filteredReplenishmentAnalysis = [];
          state.replenishmentAnalysisLastUpdateDate = "";
        }
      )
      .addCase(createOverstockAnalysis.pending, (state) => {
        state.overstockAnalysisLoading = true;
        state.overstockAnalysisError = "";
      })
      .addCase(createOverstockAnalysis.fulfilled, (state, { payload }) => {
        state.overstockAnalysisLoading = false;
        state.overstockAnalysis = payload.data;
        state.filteredOverstockAnalysis = payload.data;
        state.overstockAnalysisLastUpdatedDate = payload.lastUpdated;
      })
      .addCase(createOverstockAnalysis.rejected, (state, { payload }) => {
        state.overstockAnalysisLoading = false;
        state.overstockAnalysisError = payload;
      })
      .addCase(getAllItems.pending, (state) => {
        state.allItemsLoading = true;
        state.allItemsError = "";
      })
      .addCase(getAllItems.fulfilled, (state, { payload }) => {
        state.allItemsLoading = false;
        state.allItems = payload.data;
        state.filteredAllItems = payload.data;
        state.allItemsLastUpdateDate = payload.allItemsLastUpdateDate;
      })
      .addCase(getAllItems.rejected, (state, { payload }) => {
        state.allItemsLoading = false;
        state.allItemsError = payload;
      });
  },
});
export const {
  clearStates,
  setFilteredUnderstockAnalysis,
  resetUnderstockAnalysisFilters,
  setFiltered,
  resetFiltered,
} = procurementSlice.actions;
export default procurementSlice.reducer;
