import { useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { setInitialPickData } from "../../../redux/features/pick-order/pickOrderSlice";
import {
  updatePickLockedBy,
  updatePickLockedByMongo,
} from "../../../utils/helper-functions/pick-order/updatePickLockedBy";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import Loading from "../../../components/Loading";
import axios from "../../../axios/axios.config";

const PickOrderSearchPageMulti = () => {
  const [order, setOrder] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userOrders, setUserOrders] = useState(null);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const {
    currentNetsuiteLocationIds,
    currentSubsidiary,
    username,
    currentDepartment,
  } = useSelector((state) => state.user);

  useEffect(() => {
    //get user orders from Netsuite
    getUserOrders();
    //if order search params present then submit on page load
    if (searchParams.get("order")) {
      handleSubmit();
    }
  }, []);

  useEffect(() => {
    // Programmatically hide the input keyboard
    if (inputRef.current) {
      inputRef.current.inputMode = "none";
    }
  }, [inputRef.current]);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    } //when coming from special orders this isint needed
    setLoading(true);
    try {
      const starttime = new Date().toISOString();
      if (!order && !searchParams.get("order"))
        throw new Error("Order is required");

      const response = await axios.post(
        `netsuite/post/pick-order/order/${
          order ? order : searchParams.get("order")
        }`,
        {
          currentNetsuiteLocationIds,
          userSubsidiary: currentSubsidiary,
          checkTO: true,
        }
      );

      const {
        customer,
        brandpackaging,
        internalid,
        location,
        locationinternalid,
        mustshipwith,
        picklockedby,
        subsidiary,
        tranid,
        trantype,
        warehousenotes,
        onefulfillment,
        shipmethod,
        whseorderstatus,
        shipmentsequence,
        ponumber,
        shippingaddress,
        relatedtransferorders,
        projectedpickqty,
        splitorderpicking,
        trandate,
        destinationlocationid,
      } = response.data[0];
      //check if order is a split
      let splitnumber = splitorderpicking ? splitorderpicking : null;

      if (!splitnumber && !picklockedby) {
        //new order, make call to netsuite to update picklockedby (only if order isint a split order)
        updatePickLockedBy({ username, internalid, trantype });
      }
      //this checks pick locked by of non-split orders (pick locked by wont exist on split orders)
      if (
        picklockedby &&
        picklockedby.toLowerCase() !== username.toLowerCase()
      ) {
        throw new Error(
          `This order is already being picked by ${picklockedby}`
        );
      }

      let finalOrder = null;

      //check to see if data exists in mongo
      let orderResponseCheck = await axios.get(
        `pick-order/get/order/${tranid}`
      );

      if (!orderResponseCheck.data) {
        //new order
        let path = response.data.map((item) => {
          return {
            bininternalid: parseInt(item.bininternalid),
            binnumber: item.binnumber,
            binonhandavailable: parseInt(item.binonhandavailable),
            lineid: parseInt(item.lineid),
            lineitemboxsize: item.lineitemboxsize,
            lineitemclass: item.lineitemclass,
            lineiteminternalid: parseInt(item.lineiteminternalid),
            lineitemname: item.lineitemname,
            lineitemqty: parseInt(item.lineitemqty),
            lineitemqtycommitted: parseInt(item.lineitemqtycommitted),
            lineitemqtypicked: parseInt(item.lineitemqtypicked),
            lineitemqtyremaining: parseInt(item.lineitemqtyremaining),
            lineitemsubclass: item.lineitemsubclass,
            lineitemupc: item.lineitemupc,
            lineitemvpn: item.lineitemvpn,
          };
        });

        //sort the path to have OZ bins on the bottom (deprecated already being done on search)
        // const sortedPath = sortObjectsByBinnumber(path);

        //check for autozone vdp cva customer and reverse walkroute
        if (customer === "AUTOZONE VDP CVA") {
          path = path.reverse();
        }

        const newMongoOrder = await axios.post(
          "pick-order/post/create/order/",
          {
            orderData: {
              username,
              path,
              customer,
              brandpackaging,
              internalid,
              location,
              locationinternalid,
              mustshipwith,
              picklockedby: username,
              subsidiary,
              tranid,
              trantype,
              warehousenotes,
              onefulfillment,
              shipmethod,
              whseorderstatus,
              shipmentsequence,
              ponumber,
              starttime,
              shippingaddress,
              relatedtransferorders,
              projectedpickqty,
              splitnumber,
              trandate,
              destinationlocationid,
            },
          }
        );

        finalOrder = newMongoOrder.data;
      } else {
        //order exists in mongo
        //pick lock exists
        if (orderResponseCheck.data.picklockedby) {
          //only error out if the names dont match on a split order
          if (
            splitnumber &&
            orderResponseCheck.data.picklockedby?.toLowerCase() !==
              username.toLowerCase()
          ) {
            throw new Error(
              `This order is already being picked by ${orderResponseCheck.data.picklockedby}`
            );
          }
        } else {
          //no pick locked by exists but order exists
          orderResponseCheck = await updatePickLockedByMongo({
            id: orderResponseCheck.data._id,
            value: username,
          });
        }

        finalOrder = orderResponseCheck?.data;
      }

      if (!finalOrder) throw new Error("Could Not Load Or Create Order");

      //save data to redux
      dispatch(setInitialPickData(finalOrder));
      let navigatePageUrl = `order/${tranid}`;
      //navigates to either special orders page or search page
      let redirect = `/portal/${currentDepartment?.toLowerCase()}/pick-order`;
      let originalPageUrl = searchParams.get("original-page")
        ? searchParams.get("original-page")
        : null;

      if (searchParams.get("redirect")) {
        redirect = searchParams.get("redirect");
      }

      navigatePageUrl += `?redirect=${encodeURIComponent(redirect)}`;

      if (originalPageUrl) {
        navigatePageUrl += `&original-page=${encodeURIComponent(
          originalPageUrl
        )}`;
      }
      //navigate to page and have original url to navigate back to if needed
      navigate(navigatePageUrl);

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Loaded Order",
          duration: 3000,
        })
      );
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    } finally {
      setLoading(false);
    }
  };

  const getUserOrders = async () => {
    try {
      const nsResponse = await axios.get(
        `netsuite/get/pick-order/user/${username}/orders`
      );

      setUserOrders(nsResponse.data);
    } catch (error) {
      setUserOrders([]);
    }
  };

  const handleCardClick = (tranid) => {
    setOrder(tranid);
    // Scroll back to the top of the page
    window.scrollTo(0, 0);
  };

  //sorts the path from mongo to have  OZ bins
  const sortObjectsByBinnumber = (path) => {
    const ozObjects = [];
    const otherObjects = [];

    for (const obj of path) {
      if (obj?.binnumber?.startsWith("OZ_RECEIVING_")) {
        ozObjects.push(obj);
      } else {
        otherObjects.push(obj);
      }
    }

    // Combine the two arrays, OZ objects at the end
    const sortedPath = [...otherObjects, ...ozObjects];
    return sortedPath;
  };

  if (loading) return <Loading message="Loading Order..." />;

  return (
    <Container maxWidth="xl">
      <Container maxWidth="sm">
        <Typography variant="h4" textAlign="center" gutterBottom>
          Pick Order
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            autoFocus
            label="Enter Order"
            name="order"
            error={Boolean(error)}
            helperText={error}
            value={order}
            onChange={(event) => setOrder(event.target.value.toUpperCase())}
            onClick={() => (inputRef.current.inputMode = "text")}
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Container>

      {userOrders ? (
        <Box mt={3}>
          <Typography variant="h5" textAlign="center" gutterBottom>
            Your Orders ({userOrders.length})
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            pb={1}
            gap={1}
          >
            {userOrders.length ? (
              userOrders.map((order) => (
                <Grid key={order.tranid} item xs={12} sm={6} md={3}>
                  <Card
                    style={{
                      height: "100%",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    variant="outlined"
                    onClick={() => handleCardClick(order.tranid)}
                  >
                    <CardContent style={{ paddingBottom: "16px" }}>
                      <Typography variant="subtitle1">
                        {order.tranid}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Date:</strong> {order.trandate}
                      </Typography>
                      {order.shipmethod && (
                        <Typography variant="body2">
                          <strong>Ship Method:</strong> {order.shipmethod}
                        </Typography>
                      )}
                      {order.entity && (
                        <Typography variant="body2">
                          <strong>Customer:</strong> {order.entity}
                        </Typography>
                      )}
                      <Typography variant="body2">
                        <strong>Location:</strong> {order.location}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Projected Pick Qty:</strong>{" "}
                        {order.projectedpickqty}
                      </Typography>
                      {order.relatedtos && (
                        <Typography variant="body2">
                          <strong>Related TO:</strong> {order.relatedtos}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography textAlign="center">No orders to display</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      ) : (
        <Loading message="Loading Your Orders..." />
      )}
    </Container>
  );
};
export default PickOrderSearchPageMulti;
